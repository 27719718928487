.goaliestats {
  color: var(--primary-black);
  font-size: 10px;
  width: 90%;
  border-collapse: collapse;
  margin: 20px 0px;
  text-align: center;
}

.goaliestats tr:nth-child(odd) {
  background-color: white;
}

.goaliestats tr:nth-child(even) {
  background-color: var(--secondary-grey);
}

.goaliestats th {
  color: white;
  background-color: var(--primary-black);
}

.goaliestats--player-cell {
  display: flex;
  align-items: center;
  min-width: 80px;
  text-align: left;
}

@media (min-width: 768px) {
  .goaliestats {
    font-size: 16px;
  }
}

@media (min-width: 1440px) {
  .goaliestats {
    font-size: 20px;
  }
}
