:root {
  --primary-red: #c00;
  --primary-black: #111;
  --primary-grey: #ddd;
  --secondary-black: #222;
  --secondary-grey: #aaa;
  --font-family: "Nunito Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

body {
  margin: 0;
  background-color: var(--primary-grey);
  font-family: var(--font-family);
  color: var(--primary-black);
}

.webpage--header {
  position: sticky;
  top: 0;
  z-index: 1000000;
}

.webpage--sidebar {
  position: sticky;
  top: 0;
  left: 0;
}

.webpage--footer {
  z-index: 1000000;
}

.webpage main {
  display: flex;
  flex-direction: row;
}

@media (max-width: 1023px) {
  .webpage--sidebar {
    display: none;
  }
}

@media (min-width: 768px) {
  .webpage {
    height: 100%;
  }
}

@media (min-width: 1024px) {
  .webpage--sidebar {
    height: calc(100vh - 230px);
    top: 230px;
  }
}

@media (min-width: 1440px) {
  .webpage--sidebar {
    height: calc(100vh - 255px);
    top: 255px;
  }
}

@media (min-width: 2560px) {
  .webpage--sidebar {
    height: calc(100vh - 275px);
    top: 275px;
  }
}
