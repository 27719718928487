.gallerypage {
  display: flex;
  flex-direction: column;
}

.gallerypage--photo-section {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  justify-content: center;
}

.gallerypage--photo-section img {
  padding: 10px;
}

.gallerypage h1 {
  padding: 20px;
}
