.sidebar {
  border-bottom: 1px solid rgba(0, 0, 0, 0);
  width: 240px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background-color: var(--secondary-grey);
  font-size: 16pt;
  height: 100%;
  position: sticky;
}

@media (min-width: 1440px) {
  .sidebar {
    width: 320px;
    font-size: 12pt;
  }
}
