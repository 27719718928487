.standings {
  font-size: 12px;
  width: 90%;
  border-collapse: collapse;
  margin: 20px 0px;
}

.standings th {
  background-color: var(--primary-black);
  color: white;
}

.standings tr:nth-child(odd) {
  background-color: white;
}

.standings tr:nth-child(even) {
  background-color: var(--secondary-grey);
}

.standings--header {
  line-height: 20px;
}
.standings--rank-cell {
  min-width: 15px;
  text-align: center;
}

.standings--team-cell {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: 700;
  height: 100%;
}

.standings--stat-cell {
  min-width: 15px;
  text-align: center;
}

@media (min-width: 768px) {
  .standings {
    font-size: 20px;
  }

  .standings--header {
    line-height: 40px;
  }
}

@media (min-width: 1440px) {
  .standings {
    font-size: 30px;
  }
}

.sidebar-standings {
  font-size: 14px;
  width: 90%;
  border-collapse: collapse;
}

.sidebar-standings th {
  background-color: var(--primary-black);
  color: white;
}

.sidebar-standings tr:nth-child(odd) {
  background-color: white;
}

.sidebar-standings tr:nth-child(even) {
  background-color: var(--primary-grey);
}

.sidebar-standings--rank-cell {
  min-width: 15px;
  text-align: center;
}

.sidebar-standings--header-team-cell {
  justify-content: center;
}

.sidebar-standings--team-cell {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: 700;
}

.sidebar-standings--stat-cell {
  min-width: 15px;
  text-align: center;
}

@media (min-width: 1440px) {
  .sidebar-standings {
    font-size: 16px;
  }

  .sidebar-standings--header {
    line-height: 30px;
  }
}
