.schedule {
  width: 90%;
  font-size: 8px;
  border-collapse: collapse;
  margin: 20px 0px;
  background-color: var(--primary-black);
  color: white;
}

.schedule th {
  height: 20px;
}

.schedule tbody {
  color: var(--secondary-black);
  height: 30px;
}

.schedule--row-odd {
  background-color: white;
}

.schedule--row-even {
  background-color: var(--secondary-grey);
}

.schedule--team-cell {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: 900;
}

.schedule--center {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (min-width: 768px) {
  .schedule {
    font-size: 10pt;
  }

  .schedule th {
    height: 30px;
  }

  .schedule tbody {
    height: 40px;
  }
}

@media (min-width: 1440px) {
  .schedule {
    font-size: 14pt;
  }

  .schedule th {
    height: 40px;
  }

  .schedule tbody {
    height: 70px;
  }

  .schedule td {
    height: 50px;
  }
}
