.navbar {
  width: auto;
  display: flex;
  flex-direction: column;
}

.navbar nav {
  background-color: var(--primary-red);
  width: 100%;
  height: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: left;
}

.navbar--white-line {
  width: 100%;
  height: 7.5px;
  background-color: white;
}

.navbar--black-line {
  width: 100%;
  height: 7.5px;
  background-color: var(--primary-black);
}

.navbar--link {
  text-decoration: none;
  padding: 1px;
}

.navbar--logo {
  padding-top: 3px;
}

.navbar--contact {
  display: flex;
  padding: 10px;
  justify-content: flex-end;
  margin-left: auto;
  color: white;
  font-size: 12px;
}

@media (max-width: 767px) {
  .navbar--contact {
    display: none;
  }
}

@media (min-width: 768px) {
  .navbar nav {
    height: 45px;
  }

  .navbar--white-line,
  .navbar--black-line {
    height: 10px;
  }

  .navbar--link {
    text-decoration: none;
    padding: 5px;
  }
}

@media (min-width: 1024px) {
  .navbar nav {
    height: 60px;
  }

  .navbar--white-line,
  .navbar--black-line {
    height: 12.5px;
  }

  .navbar--link {
    text-decoration: none;
    padding: 8px;
  }

  .navbar--contact {
    font-size: 14px;
  }
}

@media (min-width: 1440px) {
  .navbar nav {
    height: 75px;
  }

  .navbar--white-line,
  .navbar--black-line {
    height: 15px;
  }

  .navbar--contact {
    font-size: 16px;
  }
}
