.header {
  width: 100%;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--secondary-grey);
}

@media (min-width: 768px) {
  .header {
    height: 100px;
  }
}

@media (min-width: 1024px) {
  .header {
    height: 120px;
  }
}

@media (min-width: 2560px) {
  .header {
    height: 140px;
  }
}
