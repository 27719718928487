.infopage {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 50px 0px;
}

.infopage--body {
  max-width: 1120px;
  width: 90%;
}
