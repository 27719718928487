.tiebreak {
  width: 90%;
  background-color: var(--secondary-grey);
  border-radius: 5px;
  margin-top: 20px;
  padding: 10px 0px;
  max-width: 500px;
}

.tiebreak h2 {
  margin: 0px 0px 10px 10px;
}

.tiebreak h6 {
  margin: 0px 10% 10px 10%;
}
