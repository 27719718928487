.button {
  font-weight: 700;
  border: 0;
  border-radius: 0.5em;
  cursor: pointer;
  display: inline-block;
  line-height: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.button:hover {
  background-color: var(--secondary-black);
  color: white;
}

.button--primary {
  color: var(--secondary-black);
  background-color: white;
}

.button--secondary {
  color: white;
  background-color: var(--primary-red);
}

.button--mobile {
  font-size: 12px;
  padding: 10px;
  width: 65px;
  height: 20px;
}

.button--tablet {
  font-size: 14px;
  padding: 14px;
  width: 110px;
  height: 30px;
}

.button--laptop {
  font-size: 16px;
  padding: 18px;
  width: 140px;
  height: 40px;
}

.button--desktop {
  font-size: 18px;
  padding: 22px;
  width: 170px;
  height: 50px;
}
