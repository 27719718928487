.matchup {
  background-color: var(--primary-grey);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: var(--primary-black);
  border-left: 1px solid var(--primary-black);
  border-right: 1px solid var(--primary-black);
  width: 120px;
  height: 70px;
}

.matchup h6 {
  margin: 0px;
}

.matchup--info {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 2px;
}

.matchup--info h6 {
  font-size: 8px;
}

.matchup--info-game {
  text-align: right;
}

.matchup--team {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2px;
}

.matchup--team-left {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.matchup--team-name {
  font-size: 8px;
}

@media (min-width: 1024px) {
  .matchup {
    width: 180px;
    height: 100px;
  }

  h6 {
    font-size: 16px;
  }
  .matchup--team-name,
  .matchup--info h6 {
    font-size: 14px;
  }
}
