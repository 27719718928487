.infotable {
  background-color: var(--secondary-grey);
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 10px;
  padding: 20px;
  height: fit-content;
  margin-bottom: 30px;
}

.infotable img {
  width: 100%;
}

.infotable h1 {
  text-align: center;
}

.infotable--information {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.infotable--table {
  border-collapse: collapse;
  width: 100%;
}

.infotable--row {
  border-top: 1px solid;
}

.infotable td {
  font-size: 12px;
  padding: 10px;
}

.infotable td:nth-child(odd) {
  font-weight: 700;
}

.infotable td:nth-child(even) {
  text-align: right;
}

@media (min-width: 768px) {
  .infotable {
    font-size: 20px;
  }

  .infotable img,
  .infotable--table {
    width: 80%;
  }

  .infotable td {
    font-size: 16px;
    padding: 15px;
  }
}

@media (min-width: 1440px) {
  .infotable--information {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .infotable img {
    width: 50%;
    padding-right: 20px;
  }

  .infotable td {
    font-size: 16px;
  }
}
