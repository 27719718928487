.statspage {
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 20px 0px;
}

.statspage--body {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 1120px;
}

.statspage--header {
  width: 90%;
  padding: 20px 0px 10px 0px;
}

.statspage h1 {
  margin: 0px;
  font-size: 40px;
}

.statspage--header h2 {
  margin: 40px 0px 0px 0px;
}

@media (min-width: 768px) {
  .statspage {
    padding: 50px 0px;
  }
}
