.homepage {
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  overflow-x: hidden;
}

.homepage a {
  text-decoration: none;
}

@media (max-width: 768px) {
  .homepage {
    padding: 10px;
  }
}
